.header {
  &__top {
    padding: 10px 25px 10px 0;
  }

  &__body {
    padding-right: 25px;
  }

  &__top {
    display: flex;
    justify-content: flex-end;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #1168a7;

    .header__lang {
      margin: 0 150px 0 20px;
    }
  }

  &__logo {
    width: 15%;
    margin-left: 10%;

    img {
      width: 100%;
    }
  }

  &__nav {
    &-inner {
      z-index: 10;
      display: flex;
      align-items: center;
      padding: 30px 0;
      margin: 0;
      transition: all 0.2s linear;
    }

    .menu-close {
      position: relative;
      width: 40px;
      height: 40px;
      align-self: flex-end;
      margin: 30px 20px 0 0;
      cursor: pointer;
      display: none;

      &:after,
      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        background-color: #fff;
        border-radius: 10px;
        top: 50%;
      }

      &:after {
        transform: translateY(-50%) rotate(45deg);
      }

      &:before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    &-item {
      display: flex;
      list-style: none;
      border-right: 3px solid #fff;
      font-size: 18px;
      padding: 0 5px;
      line-height: 0.8;

      white-space: nowrap;

      & > a {
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
      }

      .active {
        color: #00f0ff;
      }

      &:last-child {
        border: none;
      }
    }

    &-burger {
      width: 40px;
      height: 30px;
      flex-direction: column;
      justify-content: space-between;
      display: none;
      cursor: pointer;

      &:after,
      &:before,
      span {
        content: "";
        width: 100%;
        height: 4px;
        background-color: #fff;
        border-radius: 10px;
      }
    }
  }

  &__lang {
    display: flex;
    align-items: center;

    &-item {
      cursor: pointer;
      transition: all 0.2s linear;

      img {
        height: 32px;
      }

      & + .header__lang-item {
        margin-left: 10px;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }

  &__mode {
    display: flex;
    align-items: center;
    margin-left: 30px;

    & > span {
      font-size: 22px;
      color: #1168a7;
    }

    & > input {
      margin: 0 20px;
    }

    &-toggle {
      position: relative;
      display: block;
      width: 100px;
      height: 26px;
      background-color: #1168a7;
      border-radius: 50px;
      border: 3px solid #ccc;
      cursor: pointer;
      margin: 0 15px;

      input {
        display: none;

        &:checked + span {
          left: calc(100% - 52px);
        }
      }

      span {
        position: absolute;
        top: 50%;
        left: calc(0% - 4px);
        transform: translateY(-50%);
        display: block;
        background-image: url(./assets/mode_button.svg);
        width: 58px;
        height: 58px;
        transition: all 0.2s linear;
      }
    }
  }

  @media (max-width: 1200px) {
    &__lang {
      &-item {
        img {
          width: 50px;
          height: auto;
        }
      }
    }

    &__mode {
      span {
        font-size: 24px;
      }

      &-toggle {
        width: 80px;
        height: 40px;

        input {
          &:checked + span {
            left: calc(100% - 39px);
          }
        }

        span {
          width: 42px;
          height: 42px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          left: 0;
        }
      }
    }
  }

  @media (max-width: 992px) {
    &__nav-inner {
      padding: 20px 0;
    }

    &__logo {
      margin-left: 15px;
    }

    &__body {
      .header__lang {
        margin-right: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    &__logo {
      margin-left: 15px;
      width: 200px;
    }

    &__nav {
      position: relative;

      .menu-close {
        display: block;
      }

      &-inner {
        width: 300px;
        height: 100%;
        background-color: #1168a7;
        position: fixed;
        left: 1000%;
        top: 0;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        padding: 0 0 0 15px;

        &--active {
          left: calc(100% - 300px);
        }
      }

      &-item {
        white-space: normal;
        border: none;
        line-height: 1;
        margin-bottom: 20px;

        a {
          font-size: 25px;
        }
      }

      &-burger {
        display: flex;
      }
    }
  }

  @media (max-width: 650px) {
    &__lang {
      align-items: center;

      &-item {
        img {
          width: 35px;
          height: auto;
        }
      }
    }

    &__nav {
      .header__lang {
        &-item {
          img {
            width: 40px;
            height: auto;
          }
        }
      }
    }

    &__mode {
      span {
        font-size: 18px;
      }

      &-toggle {
        width: 75px;
        height: 30px;

        input {
          &:checked + span {
            left: calc(100% - 29px);
          }
        }

        span {
          width: 32px;
          height: 32px;
          left: 0;
        }
      }
    }
  }

  @media (max-width: 475px) {
    &__top {
      padding: 5px;
    }

    &__lang {
      align-items: center;
      &-item {
        img {
          width: 20px;
          height: auto;
        }
      }
    }

    &__mode {
      margin-left: 10px;
      span {
        font-size: 14px;
      }

      &-toggle {
        width: 50px;
        height: 20px;

        input {
          &:checked + span {
            left: calc(100% - 19px);
          }
        }

        span {
          width: 22px;
          height: 22px;
          left: -3%;
        }
      }
    }
  }
}

.header--consumer {
  .header__nav {
    &-inner {
      padding: 10px 0;
    }

    &-item {
      border: none;
    }
  }
}
