.login-modal {
  &__form {
    display: flex;
    flex-direction: column;
  }

  .modal__body {
    width: 400px;
    height: 400px;
    background-color: #fff;
    padding: 20px;
    text-align: center;
  }

  input {
    padding: 10px;
  }

  // button {
  //   margin-top: 100px;
  // }
}
