.login {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 470px;
  padding: 85px 85px 60px 85px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 148, 255, 0.38);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  &__back {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 5px;
    left: 20px;
    cursor: pointer;

    span {
      position: absolute;
      background-color: #fff;
      width: 40px;
      height: 4px;
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      background-color: #fff;
      width: 15px;
      height: 4px;
      display: block;
      left: -2px;
    }

    &::after {
      transform: translateY(-50%) rotate(45deg);
      top: calc(50% + 4.5px);
    }

    &::before {
      transform: translateY(-50%) rotate(-45deg);
      top: calc(50% - 5px);
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &-control {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    label {
      font-weight: 500;
      font-size: 24px;
    }

    &-input {
      width: 70%;
      height: 65px;
      min-width: 450px;
      background-color: #c4c4c4;
      border: none;
    }

    &-submit {
      width: 35%;
      background-color: #1168a7;
      text-transform: uppercase;
      color: #fff;
      border: none;
      border-radius: 10px;
      font-weight: 500;
      padding: 20px 50px;
      font-size: 24px;
      text-align: center;
      cursor: pointer;
    }

    &-res-password {
      color: #030086;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 24px;
    }
  }

  @media (max-width: 992px) {
    padding: 70px 70px 45px 70px;

    &__form {
      &-control {
        flex-direction: column;
        align-items: flex-start;
      }

      &-input {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  @media (max-width: 992px) {
    &__form {
      &-control {
        flex-direction: column;
        align-items: flex-start;
      }

      &-input {
        width: 100%;
        margin-top: 15px;
        min-width: 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 55px 40px 30px 40px;

    width: 90%;

    label {
      font-size: 18px;
    }

    &__form {
      &-submit {
        width: 100%;
        font-size: 18px;
      }

      &-res-password {
        font-size: 20px;
      }

      &-input {
        height: 50px;
      }
    }
  }

  @media (max-width: 650px) {
    padding: 55px 25px 30px 25px;

    &__back {
      left: 25px;
      span {
        width: 26px;
        height: 3px;
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 10px;
        height: 3px;
      }

      &::before {
        top: calc(50% - 3px);
      }

      &::after {
        top: calc(50% + 3px);
      }
    }
  }
}
