.questions {
  padding-top: 30px;
  padding-bottom: 30px;

  .timing {
    img {
      width: 50%;
      min-width: 250px;
    }
  }

  @media (max-width: 992px) {
    .timing {
      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    .timing {
      img {
        width: 50%;
        text-align: center;
      }
    }
  }
}
