.footer {
  flex-shrink: 0;

  &__inner {
  }

  &__body {
    padding: 15px 0;
    display: flex;
    background-color: #1168a7;
  }

  &__logo {
    width: 60%;
    margin-left: 10%;
  }

  &__bottom {
    background-color: #010952;
    text-align: center;
    color: #fff;
    font-weight: 400;
    padding: 10px 0;
    font-size: 14px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;
    margin-right: auto;

    &-links {
      display: flex;
      padding: 0;
      list-style: none;
      flex-wrap: wrap;

      &-item {
        border-right: 3px solid #fff;
        padding: 5px;
        line-height: 0.8;
        white-space: nowrap;

        a {
          font-size: 16px;
          color: #fff;
          text-decoration: none;
          margin-right: 10px;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &-copy {
      font-size: 16px;
      color: #fff;
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    &__bottom {
      font-size: 13px;
    }

    &__info {
      &-links-item > a,
      &-copy {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 650px) {
    &__body {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      align-items: center;
      margin: 0;

      &-links {
        align-items: center;
        justify-content: center;

        &-item {
          border: none;

          a {
            font-size: 15px;
          }
        }
      }
    }

    &__logo {
      width: 200px;
      margin: 0;
    }
  }
}
