.modal {
  display: none;
  position: fixed;
  z-index: 15;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;

  &--open {
    display: flex;
  }

  .modal__overlay {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: #686868;
    opacity: 0.9;
    display: block;
    z-index: 15;
  }

  .modal__body {
    position: relative;
    z-index: 20;
    width: 34.4rem;
  }

  .modal__close {
    width: 25px;
    height: 25px;
    position: absolute;
    left: calc(100% - 35px);
    cursor: pointer;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 25px;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      transform: translateY((-50%));
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}
