.page {
  width: 100%;
  padding: 10px 40px;
  min-height: calc(100vh - 250px);

  &__title {
    font-size: 64px;
    color: #000;
    font-weight: 500;
    margin: 0;
  }

  .page__block-title {
    font-size: 36px;
    font-weight: 500;
    margin: 20px 0 0 0;
  }

  .page__block-text {
    font-size: 24px;
    margin: 0;
  }

  @media (max-width: 992px) {
    &__title {
      font-size: 54px;
    }

    .page__block-title {
      font-size: 30px;
    }

    .page__block-text {
      font-size: 20px;
    }
  }

  select,
  input:not([type="checkbox"]),
  textarea {
    background-color: #c4c4c4;
    display: block;
    width: 100%;
    border: none;
    resize: none;
  }

  input[type="checkbox"] {
    min-width: 20px;
    min-height: 20px;
    margin-right: 20px;
    margin-top: 10px;
  }

  label {
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
    margin: 10px 0;
    font-size: 20px;
  }

  .control {
    margin-bottom: 20px;
  }

  select,
  input {
    padding: 25px 10px;
  }

  select {
    appearance: none;
    background: url("./assets/arrow.svg") no-repeat 98% 50%, #c4c4c4;
  }

  textarea {
    height: 150px;
  }

  label {
    display: flex;
    align-items: center;
  }

  button[type="submit"] {
    width: 100%;
    background-color: #1168a7;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    padding: 15px 0;
    cursor: pointer;
  }

  @media (max-width: 1500px) {
    input[type="checkbox"] {
      min-width: 15px;
      min-height: 15px;
    }

    label {
      font-size: 16px;
    }
  }

  @media (max-width: 650px) {
    padding: 10px 25px;

    &__title {
      font-size: 35px;
    }

    .page__block-title {
      font-size: 20px;
    }

    .page__block-text {
      font-size: 14px;
    }
  }
}

.card {
  background-color: rgba(0, 148, 255, 0.38);
  padding: 10px 10px 10px 25px;
  width: 420px;
  float: right;
  margin-left: 50px;
  border-radius: 3px;

  & + .card {
    margin-top: 35px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 15px 0;
  }

  &__text {
    display: inline;
    font-weight: 500;
  }

  &__img {
    float: right;
    width: 177px;
    height: 130px;
  }

  &__button {
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    background-color: #1168a7;
    border: none;
    border-radius: 10px;
    padding: 10px 35px;
    margin-top: 15px;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    width: 350px;

    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 14px;
    }

    &__img {
      width: 150px;
      height: 115px;
    }

    &__button {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 7px 7px 7px 15px;
    margin: 15px 0;

    &__title {
      font-size: 16px;
    }

    &__text {
      font-size: 13px;
    }

    &__img {
      width: 120px;
      height: 120px;
    }

    &__button {
      margin-top: 15%;
      font-size: 15px;
    }
  }
}
