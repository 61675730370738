.home-merchant {
  padding: 20px 300px;

  &__title {
    font-weight: 400;
    color: #010952;
    margin: 0;
    padding-bottom: 15px;
  }

  &__text {
    font-size: 24px;
    font-weight: 400;
    // text-transform: uppercase;ы
    margin: 0;
  }

  .card {
    width: auto;
    margin-left: 0;

    img {
      width: 50%;
    }

    @media (max-width: 768px) {
      img {
        width: 160px;
      }
    }

    @media (max-width: 400px) {
      img {
        width: 50%;
      }
    }
  }

  &__card {
    background-color: rgba(0, 148, 255, 0.38);
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 24px;
      margin: 0;
    }

    &-text {
      text-transform: uppercase;
      font-weight: 500;
    }

    &-link {
      position: relative;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      margin-top: 20px;
      border: none;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 2px;
        background-color: #fff;
        display: block;
        left: 110%;
      }

      &::after {
        transform: rotate(-45deg);
        top: 10px;
      }

      &::before {
        transform: rotate(45deg);
        top: 4px;
      }
    }

    @media (max-width: 992px) {
      width: 100%;
      height: 95%;
      margin-bottom: 40px;
    }

    @media (max-width: 650px) {
      &-title {
        font-size: 16px;
      }

      img {
        width: 30px;
      }

      &-text {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 1650px) {
    padding: 20px 150px;
  }

  @media (max-width: 1300px) {
    padding: 20px 50px;

    &__title {
      font-size: 54px;
    }

    &__text {
      font-size: 25px;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 30px;

    &__title {
      font-size: 44px;
    }

    &__text {
      font-size: 18px;
    }
  }
}

.home-consumer {
  padding: 100px 10%;

  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding: 10px 15px 15px 15px;
    background: rgba(0, 148, 255, 0.38);
    border-radius: 10px;
    height: 100%;

    img {
      width: 60%;
    }

    &-text {
      color: #010952;
      font-weight: 500;
      font-size: 18px;
      margin: 10px 0;
    }

    &-link {
      width: 100%;
      display: block;
      text-decoration: none;
      background: #1168a7;
      border-radius: 10px;
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      padding: 20px 5px;
      border: none;
      cursor: pointer;
    }
  }

  @media (max-width: 1550px) {
    // padding: 100px 150px;
  }

  @media (max-width: 1250px) {
    // padding: 100px 50px;

    &__card {
      &-text {
        // font-size: 22px;
      }

      &-link {
        // font-size: 28px;
      }
    }
  }

  @media (max-width: 992px) {
    &__card {
      padding: 10px 15px;

      &-link {
        padding: 10px 5px;
        font-size: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 40px;

    &__card {
      height: 95%;
      margin-bottom: 20px;
    }
  }
}
