.services {
  p {
    text-transform: uppercase;
  }

  .services__conclusion {
    width: 700px;
  }

  @media (max-width: 768px) {
    .services__conclusion {
      width: 100%;
    }
  }
}
